import Animate from '../modules/animate';
export default class {
    animate;
    constructor() {
        if(!$('.js_slider').length) {
            return;
        }
        console.log($('.js_slider'));
        this.animate = new Animate();
        if ($('.js_slider').length === 1) {
            $('.js_slider').slick({
                autoplay: true,
                autoplaySpeed: 6000,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                fade: true,
                cssEase: 'linear',
                mobileFirst: true,
                waitForAnimate: true,
                draggable: false,
            })
        } else {
            $('.js_slider').slick({
                autoplay: true,
                autoplaySpeed: 6000,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                arrows: false,
                fade: true,
                cssEase: 'linear',
                mobileFirst: true,
                waitForAnimate: true,
                draggable: false,
            })
        }

        this.listen();
        this.showSlide(0);
    }
    listen() {
        $('.js_slider').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            this.hideSlide(currentSlide);
            this.showSlide(nextSlide);
        });
    }

    showSlide(index) {
        let slide = document.querySelector('[data-slick-index="' + index + '"]');
        let title = slide.querySelector('.title-slide');
        let subtitle = slide.querySelector('.subtitle-slide');
        let banner = slide.querySelector('.banner-main__list');

        this.animate.css(title, 'fadeInUp', () => {
            title.classList.add('active');
        });
        this.animate.css(banner, 'fadeInUp', () => {
            banner?.classList.add('active');
        });
        this.animate.css(subtitle, 'fadeInUp', () => {
            subtitle.classList.add('active');
        });
    }

    hideSlide(index) {
        let slide = document.querySelector('[data-slick-index="' + index + '"]');
        let title = slide.querySelector('.title-slide');
        let banner = slide.querySelector('.banner-main__list');
        let subtitle = slide.querySelector('.subtitle-slide');
        this.animate.css(title, 'fadeOutUp', () => {
            title.classList.remove('active');
        });
        this.animate.css(banner, 'fadeOutUp', () => {
            banner?.classList.remove('active');
        });
        this.animate.css(subtitle, 'fadeOutUp', () => {
            subtitle.classList.remove('active');
        });
    }
}
