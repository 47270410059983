require('./bootstrap');

import Search from './modules/search';
import Submenu from './modules/submenu';
import Mobilemenu from './modules/mobile-menu';
import Animate from './modules/animate';
import MainSlider from './modules/main-slider';
import Particle from './modules/particle';
import ClientsSlider from './modules/clients-slider';
import Up from './modules/up';
import Counter from './modules/counters';
import ClientsProdSlider from './modules/clients-prod-slider';
import ProjectsSlider from './modules/projects-slider';
import ProductsSlider from './modules/products-slider';
import TechnologySlider from './modules/technology-slider';
window.wow.init();
import SubmitForm from './modules/submit-form';
import Typed from './modules/typed';
import Map from './modules/map';
import Intl from './modules/intl-tel-input';
import FileLabel from './modules/file-label';
import Inputmask from './modules/inputmask';
import FilledInput from './modules/filled-input';
import CopyLink from './modules/copy-link';
import VacanciesFilterVue from './modules/vacanciesFilterVue';
import Select from './modules/select';
// import News from './modules/news';
import ReviewsSlider from './modules/reviews-slider';
import FuncModul from './modules/functional-modules';
import ItiOverflow from './modules/iti-overflow';
import ImagePlug from './modules/image-plug';
import Projects from './modules/projectsVue';
import Clients from './modules/clientsVue';
import SubmitDemoForm from './modules/submit-form-demo';
import SubmitResumeForm from './modules/submit-form-resume';
import CuteLink from './modules/cute-link';
import ToggleModal from './modules/toggle-modal';
import Hyphen from './modules/hyphen';
import Metrics from './modules/metrics';
import EmployeeCard from './modules/employee-card';

let search = new Search();
let submenu = new Submenu();
let mobileMenu = new Mobilemenu();
let animated = new Animate();
let mainSlider = new MainSlider();
let particle = new Particle();
let clientsSlider = new ClientsSlider();
let up = new Up();
let сounter = new Counter();
let clientsProdSlider = new ClientsProdSlider();
let projectsSlider = new ProjectsSlider();
let productsSlider = new ProductsSlider();
let technologySlider = new TechnologySlider();
let submitForm = new SubmitForm();
let typed = new Typed();
let map = new Map();
let intl = new Intl();
let fileLabel = new FileLabel();
let inputmask = new Inputmask();
let filledInput = new FilledInput();
let copyLink = new CopyLink();
let vacanciesFilterVue = new VacanciesFilterVue();
let select = new Select();
// let news = new News();
let reviewsSlider = new ReviewsSlider();
let funcModul = new FuncModul();
let itiOverflow = new ItiOverflow();
let imagePlug = new ImagePlug();
let projects = new Projects();
let clients = new Clients();
let submitDemoForm = new SubmitDemoForm();
let submitResumeForm = new SubmitResumeForm();
let cuteLink = new CuteLink();
let toggleModal = new ToggleModal();
let hyphen = new Hyphen();
let metrics = new Metrics();
let employeeCard = new EmployeeCard();