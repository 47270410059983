import Select from '../components/SelectClients';
import axios from 'axios';
import Vue from 'vue';
import  qs from 'qs';

export default class {
    appClients;

    constructor() {
        if (!document.getElementById('app')) {
            return;
        }
        this.appClients = new Vue({
            el: '#app',
            delimiters: ['{%', '%}'],
            components: {
                'select2': Select,
            },
            data: {
                apiMode: 'production',
                siteDomain: '',
                activeCountry: null,
                activeCity: null,
                allClients: null,
                allCountries: null,
                allProducts: null,
                currentCountry: 'all',
                currentProductType: 'all',
                options: {
                    offset: 0
                },
                showMap: false,
            },
            methods: {
                makeApiEndpoint(target) {
                    const ending = this.apiMode === 'local' ? '.json' : '';
                    return '/' + target + ending;
                },
                clientsSummary: function (countryAlias) {
                    let a = [];
                    for (let el in this.allClients) {
                        if (this.allClients[el].country === countryAlias) {
                            for (let pt in this.allClients[el].productTypes) {
                                let current = -1;
                                for (let i = 0; i < a.length; i++) {
                                    if (a[i].alias === this.allClients[el].productTypes[pt]) {
                                        current = i;
                                        break;
                                    }
                                }
                                if (current < 0) {
                                    let data = this.getProductData(this.allClients[el].productTypes[pt]);
                                    data.count = 1;
                                    a.push(data);
                                } else {
                                    a[current].count++;
                                }
                            }
                        }
                    }
                    return a;
                },
                getProductData: function (productType) {
                    for (let pt in this.allProducts) {
                        if (this.allProducts[pt].alias === productType) {
                            return this.allProducts[pt];
                        }
                    }
                    return [];
                },
                setCurrentCounty: function (e, country) {
                    e.preventDefault();
                    this.currentCountry = country;
                },
                setCurrentProductType: function (e, type) {
                    e.preventDefault();
                    this.currentProductType = type;
                },
                cuteLink: function (e) {
                    let el = e.currentTarget;
                    this.options.offset = el.getAttribute('data-offset') || this.options.offset;
                    let id = el.getAttribute('href')|| el.getAttribute('data-href');
                    let top = document.querySelector(id).offsetTop + (+this.options.offset);

                    $('body,html').animate({
                        scrollTop: top
                    }, 1000);
                }
            },
            computed: {
                apiDomain() {
                    return this.apiMode === 'local' ? 'http://localhost:3000/js' : `${this.siteDomain}/api/v1`;
                },
                countriesList: function () {
                    let arr = [];
                    for (let c in this.allCountries) {
                        let el = this.allCountries[c];
                        el.select = (this.currentCountry === el.alias) ? 1 : 0;
                        arr.push(el);
                    }
                    return arr;
                },
                countries: function () {
                    let arr = [];
                    for (let c in this.allCountries) {
                        if (this.currentCountry !== 'all' && this.currentCountry !== this.allCountries[c]
                            .alias) {
                            continue;
                        }
                        let el = this.allCountries[c];
                        el.items = [];
                        for (let i in this.allClients) {
                            if (this.currentProductType !== 'all') {
                                let x = 0;
                                for (let z = 0; z < this.allClients[i].productTypes.length; z++) {
                                    let v = this.allClients[i].productTypes[z];
                                    if (this.currentProductType === v) {
                                        x++;
                                    }
                                }
                                if (x < 1) continue;
                            }
                            if (this.allClients[i].country === this.allCountries[c].alias) {
                                el.items.push(this.allClients[i]);
                            }
                        }
                        if (el.items.length) {
                            arr.push(el);
                        }
                    }
                    arr = arr.sort(function (a, b) {
                        return b.items.length - a.items.length;
                    });
                    return arr;
                }
            },
            mounted() {
                this.request = axios.create({
                    method: 'get',
                    baseURL: this.apiDomain,
                    headers: { 'Accept-Language': document.documentElement.lang },
                    paramsSerializer: (params) => {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                });
                this.request(this.makeApiEndpoint('countries'), {
                    params: {
                        filter: {
                            isEmptyAlias: 0,
                            hasClients: 0 || 1,
                        },
                    }
                }).then(response => (this.allCountries = response.data.data));
                this.request(this.makeApiEndpoint('products'), {
                    params: {
                        filter: {
                            isEmptyAlias: 0,
                            hasClients: 0 || 1,
                        },
                    }
                }).then(response => (this.allProducts = response.data.data));
                this.request(this.makeApiEndpoint('clients')).then(response => (this.allClients = response.data.data));

                window.addEventListener('load', () => {
                    this.showMap = true;
                });
            },
        });
    }
}
