import Select from '../components/SelectVacancy';
import axios from 'axios';
import Vue from 'vue';
import qs from 'qs';
export default class {
    appVacancies;
    constructor() {
        if (!document.getElementById('appVacancies')) {
            return;
        }
        this.appVacancies = new Vue({
            el: '#appVacancies',
            delimiters: ['{%', '%}'],
            components: {
                'select2': Select,
            },
            data: {
                allCountries: null,
                allCities: null,
                allVacancies: null,
                allTypeVacancies: null,
                currentCountry: 'all',
                currentCity: 'all',
                currentTypeVacancy: 'all',
                country_id: document.querySelector('.js_vacancy-country').value,
                city_id: document.querySelector('.js_vacancy-city').value,
                is_hot: document.querySelector('.js_vacancy-type').value,
                hasVacancies: 0 || 1,
                domain: '',
                showBtnMore: false,
                page: 1,
            },
            methods: {
                updateVacancy() {
                    let filter = {};
                    if (this.currentCountry !== 'all') {
                        this.country_id = parseInt(this.currentCountry);
                        filter.country_id = this.country_id;
                    }

                    if (this.currentCity !== 'all') {
                        this.city_id = parseInt(this.currentCity);
                        filter.city_id = this.city_id;
                    }

                    if (this.currentTypeVacancy !== 'all') {
                        this.is_hot = this.currentTypeVacancy;
                        filter.is_hot = this.is_hot;
                    }

                    this.request('/vacancies',{
                       params: {
                           filter: filter,
                           per_page: this.perPage,
                       }
                    }).then(response => {(this.allVacancies = response.data.data);
                        if (response.data.meta.current_page === response.data.meta.last_page) {
                            this.showBtnMore = false;
                        } else if (response.data.meta.total > 6) {
                            this.showBtnMore = true;
                        }
                    });
                },

                updateCities() {
                    let filterCities = {};
                    if (this.currentCountry !== 'all') {
                        this.country_id = parseInt(this.currentCountry);
                        filterCities.country_id = this.country_id;
                        filterCities.hasVacancies = this.hasVacancies;
                    }

                    this.request('/cities',{
                        params: {
                            filter: filterCities,
                        }
                     }).then(response => (this.allCities = response.data.data));
                },

                updateCountries() {
                    let filterCountries = {};

                    if (this.currentCity !== 'all') {
                        this.city_id = parseInt(this.currentCity);
                        filterCountries.city_id = this.city_id;
                        filterCountries.hasVacancies = this.hasVacancies;
                    }

                    this.request('/countries',{
                        params: {
                            filter: filterCountries,
                        }
                     }).then(response => (this.allCountries = response.data.data));
                },

                copyToClipboard(textToCopy) {
                    // navigator clipboard api needs a secure context (https)
                    if (navigator.clipboard && window.isSecureContext) {
                        return navigator.clipboard.writeText(textToCopy);
                    } else {
                        // text area method
                        let textArea = document.createElement("textarea");
                        textArea.value = textToCopy;
                        textArea.style.position = "fixed";
                        textArea.style.left = "-999999px";
                        textArea.style.top = "-999999px";
                        document.body.appendChild(textArea);
                        textArea.focus();
                        textArea.select();
                        return new Promise((res, rej) => {
                            document.execCommand('copy') ? res() : rej();
                            textArea.remove();
                        });
                    }
                },

                copyLinkVacancy(e) {
                    const link = e.target.parentElement.previousElementSibling;
                    const href = link.getAttribute('href');
                    this.copyToClipboard(href)
                    .then(() => console.log('text copied'))
                    .catch(() => console.log('copy error'));
                },

                showMoreVacancies() {
                    this.request('/vacancies',{
                        params: {
                            page: this.page + 1,
                        }
                     }).then(response => {
                        this.allVacancies = this.allVacancies.concat(response.data.data);
                        if (response.data.meta.current_page === response.data.meta.last_page) {
                            this.showBtnMore = false;
                        };
                    });
                },
            },
            mounted() {
                let filterHasVac = {};
                filterHasVac.hasVacancies = this.hasVacancies;

                this.request = axios.create({
                    method: 'get',
                    baseURL: `${this.domain}/api/v1`,
                    headers: { 'Accept-Language': document.documentElement.lang },
                    paramsSerializer: (params) => {
                        return qs.stringify(params, {arrayFormat: 'repeat'});
                    },
                }),
                this.request('/countries', {
                    params: {
                        filter: filterHasVac,
                    }
                }).then(response => (this.allCountries = response.data.data));
                this.request('/cities', {
                    params: {
                        filter: filterHasVac,
                    }
                }).then(response => (this.allCities = response.data.data));
                this.request('/vacancies').then(response => {
                    (this.allVacancies = response.data.data);
                    if (response.data.meta.total > 6) {
                        this.showBtnMore = true;
                    };
                });

                this.request('/vacanciesTypes')
                    .then(response => {
                        this.allTypeVacancies = response.data.data;
                    });
            },
        })
    }
}
