export default class {
    constructor() {
       this.listen(); 
    }
 
    listen() {
       document.querySelectorAll('.form-control').forEach((item) => {
          item.addEventListener('focus', () => {
             item.parentElement.classList.add('filled');
          });  
 
          item.addEventListener('blur', () => {
             item.parentElement.classList.remove('filled');
             if (!item.value == '') {
                item.parentElement.classList.add('filled');
             } 
          });          
       });
    }      
 }