export default class {

    constructor() {
        $(".js_vacancy-country").select2({
            placeholder: "Все страны",
            dropdownParent: $(".js_vacancy-country").parent(),
            minimumResultsForSearch: Infinity,
        });

        $(".js_vacancy-city").select2({
            placeholder: "Все города",
            dropdownParent: $(".js_vacancy-city").parent(),
            minimumResultsForSearch: Infinity,
        });

        $(".js_vacancy-type").select2({
            placeholder: "Все вакансии",
            dropdownParent: $(".js_vacancy-type").parent(),
            minimumResultsForSearch: Infinity,
        });

        $(".js_country-resume").each(function() {
            $(this).select2({
                placeholder: "Россия",
                dropdownParent: $(this).parent(),
                minimumResultsForSearch: Infinity,
            });
        });

        $(".js_livewire_country-resume").each(function() {
            $(this).select2({
                placeholder: "Все страны",
                dropdownParent: $(".js_livewire_country-resume").parent(),
                minimumResultsForSearch: Infinity,
            }).on('change', function () {
                window.livewire.emit('selectCountry', $(this).val())
            });
        })

        $(".js_news-type").each(function() {
            $(this).select2({
                placeholder: "Все новости",
                dropdownParent: $(this).parent(),
                minimumResultsForSearch: Infinity,
            });
        });

        $(".js_news-hashtag").each(function() {
            $(this).select2({
                placeholder: "Все хэштеги",
                dropdownParent: $(this).parent(),
                minimumResultsForSearch: Infinity,
            });
        });

        $(".js_livewire-news-type").each(function() {
            $(this).select2({
                placeholder: "Все новости",
                dropdownParent: $(this).parent(),
                minimumResultsForSearch: Infinity,
            });
        }).on('change', function () {
            window.livewire.emit('filterLabels', $(this).val())
        });

        $(".js_livewire-news-hashtag").each(function() {
            $(this).select2({
                placeholder: "Все хэштеги",
                dropdownParent: $(this).parent(),
                minimumResultsForSearch: Infinity,
            });
        }).on('change', function () {
            window.livewire.emit('filterTags', $(this).val())
        });

        $(".js_project-products").each(function() {
            $(this).select2({
                placeholder: "Все продукты",
                dropdownParent: $(this).parent(),
                minimumResultsForSearch: Infinity,
            });

        }).on('change', function () {
            window.livewire.emit('filterProduct', $(this).val())
        });

        $(".js_project-country").each(function() {
            $(this).select2({
                placeholder: "Все страны",
                dropdownParent: $(this).parent(),
                minimumResultsForSearch: Infinity,
            });
        }).on('change', function () {
            window.livewire.emit('filterCountry', $(this).val())
        });

    }
}
